import $ from "jquery";
var lastScrollLeft = 0;
// $(".kt_ecommerce_table table th:last-child").hide();
// $(".kt_ecommerce_table table td:last-child").hide();
$(document).ready(function (){
    $(".kt_ecommerce_table").click(function () {
        // var documentScrollLeft = $(".kt_ecommerce_table").scrollLeft();
        // if (lastScrollLeft != documentScrollLeft) {
        //     // window.clearTimeout(timer);
        //     // timer2 = setTimeout(function() {
        //     //     // do something
        //     //     $(".kt_ecommerce_table table th:last-child").css('position','relative');
        //     //     $(".kt_ecommerce_table table td:last-child").css('position','relative');
        //     // }, 5000);
        //     $(".kt_ecommerce_table table th:last-child").css({'visibility': 'visible', 'position': 'sticky'});
        //     $(".kt_ecommerce_table table td:last-child").css({'visibility': 'visible', 'position': 'sticky'});
        //     lastScrollLeft = documentScrollLeft;
        // }
        // // $.data(this, 'scrollTimer', setTimeout(function() {
        // //     // do something
        // //     $(".kt_ecommerce_table table th:last-child").css('visibility','relative');
        // //     $(".kt_ecommerce_table table td:last-child").css('visibility','relative');
        // //  }, 5000)
        // // );
    })
    $(".kt_ecommerce_table").scroll(function () {
        // var documentScrollLeft = $(".kt_ecommerce_table").scrollLeft();
        // console.log(lastScrollLeft, documentScrollLeft)
        //
        // if (lastScrollLeft != documentScrollLeft) {
        //     console.log(lastScrollLeft, documentScrollLeft)
        //
        //     // window.clearTimeout(timer);
        //     // timer2 = setTimeout(function() {
        //     //     // do something
        //     //     $(".kt_ecommerce_table table th:last-child").css('position','relative');
        //     //     $(".kt_ecommerce_table table td:last-child").css('position','relative');
        //     // }, 5000);
        //     $(".kt_ecommerce_table table th:last-child").css({'visibility': 'visible', 'position': 'sticky'});
        //     $(".kt_ecommerce_table table td:last-child").css({'visibility': 'visible', 'position': 'sticky'});
        //     lastScrollLeft = documentScrollLeft;
        // }
        // $.data(this, 'scrollTimer', setTimeout(function() {
        //     // do something
        //     $(".kt_ecommerce_table table th:last-child").css('visibility','relative');
        //     $(".kt_ecommerce_table table td:last-child").css('visibility','relative');
        //  }, 5000)
        // );
    });
})
